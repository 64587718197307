// Generated by Framer (6124c77)

import { addFonts, cx, CycleVariantState, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {UpbCnK41p: {hover: true}};

const cycleOrder = ["UpbCnK41p"];

const serializationHash = "framer-qwF0i"

const variantClassNames = {UpbCnK41p: "framer-v-11poqyr"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.4, ease: [0.7, 0, 0.3, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "UpbCnK41p", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"instagram.com"}><Image {...restProps} as={"a"} background={{alt: "", fit: "fill", pixelHeight: 24, pixelWidth: 24, src: "https://framerusercontent.com/images/DOT1JvCQCbY101LoQisljmPhw.svg"}} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-11poqyr", className, classNames)} framer-h6zdae`} data-framer-name={"instagram"} layoutDependency={layoutDependency} layoutId={"UpbCnK41p"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"UpbCnK41p-hover": {"data-framer-name": undefined, background: {alt: "", fit: "fill", pixelHeight: 24, pixelWidth: 24, src: "https://framerusercontent.com/images/zCysTHwT3SC8ANGrLVjGzSDD1gg.svg"}}}, baseVariant, gestureVariant)}/></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-qwF0i.framer-h6zdae, .framer-qwF0i .framer-h6zdae { display: block; }", ".framer-qwF0i.framer-11poqyr { cursor: pointer; height: 24px; overflow: hidden; position: relative; text-decoration: none; width: 24px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"vVfIBskUj":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerSkVE2CAPg: React.ComponentType<Props> = withCSS(Component, css, "framer-qwF0i") as typeof Component;
export default FramerSkVE2CAPg;

FramerSkVE2CAPg.displayName = "social icons";

FramerSkVE2CAPg.defaultProps = {height: 24, width: 24};

addFonts(FramerSkVE2CAPg, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})